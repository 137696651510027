<template>
  <div>
    <div>
      <div>
        <a-card
            :bordered="true"
            style="margin-bottom: 24px"
        >
          <DescriptionList>
            <div style="text-align: right" class="table-operatorTitle">
              <label class="detailTop-edit">
                <img :src="tenant" class="icon" />&nbsp;&nbsp;{{
                  objBasic.code
                }}&nbsp;&nbsp; {{ objBasic.facilityName }}&nbsp;&nbsp;
                <div :class="this.changeImgbg()">{{ this.changeText() }}</div>
                <!--                <img :src="this.changeImg()"  />-->
              </label>
<!--              <a-button type="primary" @click="onEdit">编辑</a-button>-->
            </div>
            <div style="margin-left: 52px">
              <Description term="门店编号" class="first"
                ><label>{{ objBasic.facilityCode }}</label>
              </Description>
              <Description term="档口类型"
                ><label>{{ getStallsTypeName(objBasic.type) }}</label>
              </Description>
              <Description term="建筑等级"
                ><label>{{
                  objBasic.buildingVO && objBasic.buildingVO.engineeringLevel
                }}</label>
              </Description>
              <Description term="招商状态"
                ><label>{{ getSalesStatus(objBasic.salesStatus) }}</label>
              </Description>
              <Description style="width: 50%" term="门店地址"
                ><label>{{ objBasic.facilityAddress }}</label>
              </Description>
            </div>
          </DescriptionList>
        </a-card>
      </div>

      <div>
        <div>
          <a-card
            :bordered="true"
            style="margin-bottom: 24px"
          >
            <DescriptionList title="建筑信息" size="large">
              <div style="margin-top: 16px" class="fee">
                <Description term="档口使用面积" style="width: 25%"
                  ><label
                    >{{
                      objBasic.buildingVO && objBasic.buildingVO.usableArea
                    }}&nbsp;&nbsp;㎡</label
                  ></Description
                >
                <Description term="档口建筑面积" style="width: 25%"
                  ><label
                    >{{
                      objBasic.buildingVO && objBasic.buildingVO.totalArea
                    }}&nbsp;&nbsp;㎡</label
                  ></Description
                >
                <Description term="档口吧台面积" style="width: 25%"
                  ><label
                    >{{
                      objBasic.buildingVO && objBasic.buildingVO.barArea
                    }}&nbsp;&nbsp;㎡</label
                  ></Description
                >
                <Description term="档口电量" style="width: 25%"
                  ><label
                    >{{
                      objBasic.buildingVO &&
                        objBasic.buildingVO.actualElectricity
                    }}&nbsp;&nbsp;Kw</label
                  ></Description
                >
                <Description term="档口排风量" style="width: 25%"
                  ><label
                    >{{
                      objBasic.buildingVO && objBasic.buildingVO.actualExhaust
                    }}&nbsp;&nbsp;㎡/h</label
                  ></Description
                >
                <Description term="燃气量" style="width: 25%"
                  ><label
                    >{{
                      objBasic.buildingVO && objBasic.buildingVO.gas
                    }}&nbsp;&nbsp;㎡</label
                  ></Description
                >
                <Description term="空调配置" style="width: 25%"
                  ><label>{{
                    objBasic.buildingVO && objBasic.buildingVO.airConditioner
                  }}</label></Description
                >
              </div>
            </DescriptionList>
            <DescriptionList size="large">
              <a-card :bordered="false">
                <a-form layout="inline" :form="form">
                  <a-row :gutter="5" style="line-height: 3">
                    <a-col :md="8" :sm="24">
                      <a-form-item label="档口平面图">
                        <template>
                          <div
                            class="clearfix"
                            style="width: 70%;display: inline-block"
                          >
                            <a-upload
                              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                              listType="picture-card"
                              :fileList="fileList"
                              @preview="handlePreview"
                              @change="handleChange"
                            >
                              <div v-if="fileList.length < 1">
                                <a-icon type="plus" />
                                <div class="ant-upload-text">上传</div>
                              </div>
                            </a-upload>
                            <a-modal
                              :visible="previewVisible"
                              :footer="null"
                              @cancel="handleCancel"
                            >
                              <img
                                alt="example"
                                style="width: 100%"
                                :src="previewImage"
                              />
                            </a-modal>
                          </div>
                        </template>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-form>
              </a-card>
            </DescriptionList>
            <Divider />
            <DescriptionList title="定价信息" size="large">
              <div style="margin-top: 16px">
                <Description v-if="hasMinPriceRead" term="基础服务费底价" style="width: 25%"
                  ><label
                    >{{ objBasic.minRent }}&nbsp;&nbsp;元</label
                  ></Description
                >
                <Description v-if="hasStandardPriceRead" term="基础服务费标价" style="width: 25%"
                  ><label
                    >{{ objBasic.retailRent }}&nbsp;&nbsp;元</label
                  ></Description
                >
                <Description term="管理费定价" style="width: 25%"
                  ><label
                    >{{ objBasic.splitCostOriginal }}&nbsp;&nbsp;元</label
                  ></Description
                >
                <Description v-if="hasMinPriceRead" term="入会费底价" style="width: 25%"
                  ><label
                    >{{ objBasic.entryFeeMin }}&nbsp;&nbsp;元</label
                  ></Description
                >
                <Description v-if="hasStandardPriceRead" term="入会费标价" style="width: 25%"
                  ><label
                    >{{ objBasic.entryFeeRetailPrice }}&nbsp;&nbsp;元</label
                  ></Description
                >
              </div>
            </DescriptionList>
          </a-card>
        </div>
        <div>
          <a-card>
            <DescriptionList title="客户信息" size="large">
                <a-table
                    rowKey="id"
                    :pagination="pagination"
                    :columns="columns"
                    :dataSource="dataStaff"
                    :scroll="{ x: 1300 }"
                    @change="changePageSize"
                >
                    <span slot="serial" slot-scope="text, record, index">
                      {{ index + 1 }}
                    </span>
                  <span
                      slot="contractCode"
                      slot-scope="text, record"
                      class="blueColor"
                  >
                      <template>
                        <a @click="contractDetail(record)">{{
                            record.contractCode
                          }}</a
                        >&nbsp;&nbsp;
                      </template>
                    </span>
                  <span
                      slot="action"
                      slot-scope="text, record"
                      class="blueColor"
                  >
                      <template>
                        <a @click="handleDetails(record)">查看</a>&nbsp;&nbsp;
                        <a @click="handleEdit(record)">编辑</a>&nbsp;&nbsp;
                        <a @click="handleActive">操作日志</a>
                      </template>
                    </span>
                </a-table>
            </DescriptionList>
          </a-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DescriptionList from "@/components/DescriptionList";
import { Divider } from "ant-design-vue";
import axios from "axios";
import { mapGetters } from "vuex";
const { Description } = DescriptionList;

import tenant from "@/assets/tenant.png";
import moment from "moment";
import { defaultPagination } from "@/utils/pagination";
import { stallsType } from "@/objects/businessObjects";
import { hasPermissions } from "@/utils/auth";
import { FacilityActions } from "../../../utils/actions";

export default {
  components: {
    DescriptionList,
    Description,
    Divider
  },
  name: "stallsListDetail",
  data() {
    return {
      stallsDetailId: this.$route.query.stallsDetailId,
      stallsDetailtype: this.$route.query.stallsDetailtype,
      tenant,
      labelCol: {
        xs: { span: 24 },
        sm: { span: 7 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 13 }
      },
      fileList: [
        {
          uid: "-1",
          name: "xxx.png",
          status: "done",
          url:
            "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png"
        }
      ],
      record: {},
      oneLevelDpt: {
        id: 0,
        level: 0,
        // name: "",
        parentId: 0,
        parentName: "",
        staffDTO: {}
      },
      form: this.$form.createForm(this),
      editDetail: false,
      previewImage: "",
      previewVisible: false,
      visible: false,
      confirmLoading: false,
      objBasic:{},
      dataStaff:[],
      data: [],
      pagination: defaultPagination(() => {}),
      //表头
      columns: [
        {
          title: "客户ID",
          dataIndex: "tenantCode",
          key: "tenantCode"
        },
        {
          title: "客户名称",
          dataIndex: "tenantName",
          key: "tenantName"
        },
        {
          title: "品牌名称",
          dataIndex: "brandName",
          key: "brandName"
        },
        {
          title: "客户类型",
          dataIndex: "showTenantType",
          key: "showTenantType"
        },
        {
          title: "经营品类",
          dataIndex: "showCuisineType",
          key: "showCuisineType"
        },
        {
          title: "签订合同编号",
          // dataIndex: "contractCode",
          key: "contractCode",
          scopedSlots: { customRender: "contractCode" }
        },
        {
          title: "合同起止时间",
          dataIndex: "fullDate",
          key: "fullDate"
        },
        {
          title: "入驻状态",
          dataIndex: "showEntryStatus",
          key: "showEntryStatus"
        },
        {
          title: "合同状态",
          dataIndex: "contractStatus",
          key: "contractStatus"
        },
        {
          title: "客户状态",
          dataIndex: "tenantStatus",
          key: "tenantStatus",
          fixed: "right",
          width: 100
        }
      ],
      //合同状态
      contractStatus: [
        {
          id: 1,
          name: "预定中"
        },
        {
          id: 2,
          name: "已预订"
        },
        {
          id: 3,
          name: "预定作废"
        },
        {
          id: 4,
          name: "已签约回款"
        },
        {
          id: 5,
          name: "已退租"
        },
        {
          id: 6,
          name: "已驳回"
        },
        {
          id: 7,
          name: "已续租"
        },
        {
          id: 8,
          name: "已签约未回款"
        },
        {
          id: 9,
          name: "待修改"
        },
        {
          id: 10,
          name: "待签约"
        },
        {
          id: 11,
          name: "未签约已回款"
        }
      ],
      // salesStatus
      salesStatusList: [
        { id: 1, value: "招商中" },
        { id: 2, value: "预定中" },
        { id: 3, value: "转租中" },
        { id: 4, value: "已预定" },
        { id: 5, value: "已签约" },
        { id: 6, value: "停止招商" }
      ]
    };
  },
  created() {
    this.init();
  },
  activated() {
    this.init();
  },
  methods: {
    // 获取招商状态
    getSalesStatus(id) {
      if (!id) {
        return;
      }
      let status = "";
      this.salesStatusList.forEach(item => {
        if (item.id == id) {
          status = item.value;
        }
      });
      return status;
    },
    changePageSize(val) {
      this.getClientList(val.current, val.pageSize);
    },
    onTabChange(key, type) {
      this[type] = key;
    },
    //合同详情
    contractDetail(val) {
      this.$router.push({
        path: "/role/contractList/contractDetail",
        query: { contractDetailsId: val.id }
      });
    },
    kitchenList() {
      this.getClientList(1, 20);
    },
    onEdit() {
      this.$router.push({
        path: "/stalls/stallsList/stallsListEdit",
        query: { stallsEditId: this.$route.query.stallsDetailId }
      });
    },
    cancelstallsNew() {
      this.editDetail = false;
    },
    init() {
      axios({
        url:   this.$baseUrl + "/kitchen/query/basic",
        method: "GET",
        withCredentials: true,
        params: {
          kitchenId: this.$route.query.stallsDetailId
        }
      }).then(res => {
        if (res.data.obj) {
          this.objBasic = res.data.obj;
        }
        // this.data = res.data.rows;
      });
      this.getClientList(); //客户列表接口
    },
    // 客户列表接口
    getClientList(current, pageSize) {
      axios({
        url:   this.$baseUrl + "/tenant/kitchen/list",
        method: "GET",
        withCredentials: true,
        params: {
          rows: pageSize || 20,
          page: current || 1,
          kitchenId: this.$route.query.stallsDetailId,
        }
      }).then(res => {
        if (res.data.rows) {
          this.dataStaff = res.data.rows || [];
          this.dataStaff.forEach(item => {
            item.contractStatus = this.changeStatus(item.contractStatus);
            this.$set(item, "showTenantType", ""); //客户类型枚举
            this.$set(item, "showCuisineType", ""); //经营品类枚举
            this.$set(item, "showEntryStatus", ""); //入驻状态枚举
            // this.$set(item, "showTenantStatus", ""); //客户状态枚举
            this.$set(item, "fullDate", ""); //起止日期
            item.fullDate =
              moment(item.contractStartDateLg).format("YYYY.MM.DD") +
              "-" +
              moment(item.contractEndDateLg).format("YYYY.MM.DD");
            switch (item.tenantType) {
              case 1:
                item.showTenantType = "品牌直营";
                break;
              case 2:
                item.showTenantType = "连锁加盟";
                break;
              case 3:
                item.showTenantType = "个体客户";
                break;
            }
            switch (item.cuisineType) {
              case 1:
                item.showCuisineType = "粤菜";
                break;
              case 2:
                item.showCuisineType = "川湘菜";
                break;
              case 3:
                item.showCuisineType = "江浙菜";
                break;
              case 4:
                item.showCuisineType = "快餐便当";
                break;
              case 5:
                item.showCuisineType = "简餐沙拉";
                break;
              case 6:
                item.showCuisineType = "米粉面馆";
                break;
              case 7:
                item.showCuisineType = "饺子馄饨";
                break;
              case 8:
                item.showCuisineType = "包子粥店";
                break;
              case 9:
                item.showCuisineType = "生煎锅贴";
                break;
              case 10:
                item.showCuisineType = "小吃烧烤";
                break;
              case 11:
                item.showCuisineType = "香锅干锅";
                break;
              case 12:
                item.showCuisineType = "海鲜龙虾";
                break;
              case 13:
                item.showCuisineType = "砂锅汤类";
                break;
              case 14:
                item.showCuisineType = "火锅";
                break;
              case 15:
                item.showCuisineType = "西餐";
                break;
              case 16:
                item.showCuisineType = "东南亚菜";
                break;
              case 17:
                item.showCuisineType = "日韩菜";
                break;
              case 18:
                item.showCuisineType = "意面披萨";
                break;
              case 19:
                item.showCuisineType = "汉堡薯条";
                break;
              case 20:
                item.showCuisineType = "其他地方菜系";
                break;
              case 21:
                item.showCuisineType = "甜品饮品";
                break;
              case 22:
                item.showCuisineType = "果蔬生鲜";
                break;
              case 23:
                item.showCuisineType = "鲜花绿植";
                break;
              case 24:
                item.showCuisineType = "医药健康";
                break;
              case 25:
                item.showCuisineType = "商品超市";
                break;
              case 26:
                item.showCuisineType = "服装鞋帽";
                break;
              case 27:
                item.showCuisineType = "美妆";
                break;
            }
            switch (item.entryStatus) {
              case 1:
                item.showEntryStatus = "待入驻";
                break;
              case 2:
                item.showEntryStatus = "已入驻";
                break;
            }
            this.brandListTenantStatus(item);
          });
        }
      });
    },
    //合同状态
    changeStatus(val) {
      let name = "null";
      if (val) {
        this.contractStatus.forEach(item => {
          if (val == item.id) {
            name = item.name;
          }
        });
        return name;
      }
      return name;
    },
    brandListTenantStatus(val) {
      if (val.tenantStatus == null) {
        return;
      } else {
        switch (val.tenantStatus) {
          case 1:
            val.tenantStatus = "预定中";
            break;
          case 2:
            val.tenantStatus = "已预订";
            break;
          case 3:
            val.tenantStatus = "签约中";
            break;
          case 4:
            val.tenantStatus = "已签约";
            break;
          case 5:
            val.tenantStatus = "改造中";
            break;
          case 6:
            val.tenantStatus = "待入驻";
            break;
          case 7:
            val.tenantStatus = "已入驻";
            break;
          case 8:
            val.tenantStatus = "申请退租中";
            break;
          case 9:
            val.tenantStatus = "退租中";
            break;
          case 10:
            val.tenantStatus = "已退租";
            break;
          case 11:
            val.tenantStatus = "已离场";
            break;
          case 12:
            val.tenantStatus = "换铺申请中";
            break;
          case 13:
            val.tenantStatus = "续租申请中";
            break;
          case 14:
            val.tenantStatus = "已续租";
            break;
          case 15:
            val.tenantStatus = "换铺中";
            break;
          default:
            "无";
        }
      }
      return val.status;
    },
    handlePreview(file) {
      this.previewImage = file.url || file.thumbUrl;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.fileList = fileList;
    },
    handleCancel() {
      this.previewVisible = false;
      this.newvisible = false;
      this.editvisible = false;
      this.newequipment = false;
      this.staffEdit = false;
      this.newEle = false;
      this.visieditEle = false;
    },
    changeText() {
      if (this.objBasic.bdStatus == 2) {
        return "不可招商";
      } else if (this.objBasic.bdStatus == 1) {
        return "可招商";
      }
    },
    changeImgbg() {
      if (this.objBasic.bdStatus == 2) {
        return "close";
      } else if (this.objBasic.bdStatus == 1) {
        return "open";
      }
    },
    getStallsTypeName(id) {
      return stallsType.find(t=>t.id === id)?.name;
    }
  },
  computed: {
    ...mapGetters("users", ["authenticatedUser"]),
    // 是否有底价查看权限
    hasMinPriceRead() {
      return hasPermissions(
        [FacilityActions.MIN_PRICE_READ],
        this.authenticatedUser.actions
      );
    },
    // 是否有标价查看权限
    hasStandardPriceRead() {
      return hasPermissions(
        [FacilityActions.STANDARD_PRICE_READ],
        this.authenticatedUser.actions
      );
    },
  }
};
</script>
<style lang="less" scoped>
.open {
  color: #2fc25b;
  background: #fff;
  font-size: 1.2rem;
}
</style>

<style lang="less" scoped>
@import "~ant-design-vue/es/style/themes/default.less";

.title {
  color: @heading-color;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 29px;
}
.table-operator {
  margin-top: -24px;
  margin-bottom: 18px;
  button {
    margin-right: 8px;
  }
}
.table-operatorTitle {
  margin-bottom: 18px;
  button {
    margin-right: 8px;
  }
}
.first {
  font-weight: bolder;
}
.ant-col-md-8 {
  width: 25%;
}
</style>
